import React, { useState } from "react";
import { useForm } from "react-hook-form";
import $ from 'jquery';
import s from './MainForma.module.css'
import Modal from "../Modal/Modal";

const chatId = `${process.env.REACT_APP_TELEGRAMM_CHATID}`;
const token = `${process.env.REACT_APP_TELEGRAMM_TOKEN_GROUP}`;
let text = '';

function sendToTelegramm(token, data, chatId) {
  $.ajax({
    type: "POST",
    url: "https://api.telegram.org/bot" + token + "/sendMessage?chat_id=" + chatId,
    data: "parse_mode=HTML&text=" + encodeURIComponent(data),
  });
};

export default function MainForma() {
  const [modalActive, setModalActive] = useState(false)

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = data => {
    text = `
    Дата время: <b>${data.time}</b>
    Имя: <b>${data.name}</b>
    Телефон: <b>${data.telefon}</b>
    Маршрут: ${data.route}
    Класс авто: ${data.autoType}
    Доп. инфо: ${data.regards}`;
    sendToTelegramm(token, text, chatId);
    setModalActive(true);
    reset()
  };

  return (
    <div>
      <Modal active={modalActive} setActive={setModalActive}>
        <div className={s.textModalWrapper}>
          <div className={s.textModal}><h2>Спасибо!</h2></div>
          <div className={s.textModal}>Ваша заявка отправлена оператору, мы свяжемся с Вами через мессенджеры если они есть или перезвоним в ближайшие 5-7 минут</div>
          <button className={s.okBtn} onClick={() => setModalActive(false)}>OK</button>
        </div>
      </Modal>
      <div className={s.mainFormaWrapper}>
        <h1 className={s.sloganForm}>
          Заказать авто онлайн
        </h1>
        <form className={s.mainInputFormWrapper} onSubmit={handleSubmit(onSubmit)}>
          <div>
            <input className={s.input} type='text' placeholder="Имя *" {...register("name", {
              required: true,
            })} />
            {errors.name?.type === 'required' && <p className={s.err}>Это обязательное</p>}
          </div>
          <div>
            <input className={s.input} type='int' placeholder="Номер телефона *" {...register("telefon", {
              required: true, minLength: 10, maxLength: 12, pattern: {
                value: /^[+0-9]+$/,
                message: "Введите только цифры 0-9"
              }
            })} />
            {errors?.telefon?.message && <p className={s.err}>{errors.telefon?.message}</p>}
            {errors?.telefon?.type === 'required' && <p className={s.err}>Упс... не может быть пусто</p>}
            {errors?.telefon?.type === 'minLength' && <p className={s.err}>мин. 10 цифр</p>}
            {errors?.telefon?.type === 'maxLength' && <p className={s.err}>макс. 11 цифр</p>}
          </div>
          <div>
            <input className={s.input} type='text' placeholder="Дата и время" {...register("time")} />
          </div>
          <div>
            <textarea className={s.textarea} type='text' rows='3' wrap="soft" placeholder="Маршрут" {...register("route")} />
          </div>
          <div>
            <textarea className={s.textarea} type='text' rows='3' wrap="soft" placeholder="Дополнительная информация" {...register("regards")} />
          </div>
          <div>
            <select className={s.input} type='text' {...register("autoType")} defaultValue="Комфорт">
              <option value="Стандарт">Стандарт</option>
              <option value="Комфорт" >Комфорт</option>
              <option value="Универсал">Универсал</option>
              <option value="Минивэн">Минивэн</option>
            </select>
          </div>
          <div className={s.mainSubmit}>
            <button className={s.btnSubmit} type="submit">Отправить оператору</button>
          </div>
        </form>
      </div>
    </div>
  );
}

