import s from './Modal.module.css';

const Modal = ({ active, setActive, children }) => {

    return (
        <div className={active ? s.modalActive : s.modal} onClick={() => setActive(false)}>
            <div className={s.modalContent} onClick={e => e.stopPropagation()}>
                {children}
                
            </div>
        </div>
    );
};
export default Modal;