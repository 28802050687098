let simferopol = [
    { "city": "Ай-Даниль санаторий", "standart": 1890 },
    { "city": "Алупка", "standart": 2190 },
    { "city": "Алушта", "standart": 1390 },
    { "city": "Армянск", "standart": 2290 },
    { "city": "Балаклава", "standart": 1990 },
    { "city": "Бахчисарай", "standart": 1490 },
    { "city": "Белогорск", "standart": 1390 },
    { "city": "Береговое (ЮБК)", "standart": 2290 },
    { "city": "Береговое (Бахчисарай)", "standart": 1790 },
    { "city": "Береговое (Феодосия)", "standart": 2290 },
    { "city": "Ботанический сад", "standart": 1990 },
    { "city": "Вилино", "standart": 1790 },
    { "city": "Гаспра", "standart": 2290 },
    { "city": "Голубой залив", "standart": 2290 },
    { "city": "Гурзуф", "standart": 1890 },
    { "city": "Джанкой", "standart": 2290 },
    { "city": "Евпатория", "standart": 1490 },
    { "city": "Заозерное", "standart": 1490 },
    { "city": "Казантип (Поповка)", "standart": 2090 },
    { "city": "Канака", "standart": 2190 },
    { "city": "Кастрополь", "standart": 2190 },
    { "city": "Кацивели", "standart": 2290 },
    { "city": "Кача", "standart": 1690 },
    { "city": "Керчь", "standart": 3790 },
    { "city": "Курортное (Ленино)", "standart": 3590 },
    { "city": "Малореченское", "standart": 1990 },
    { "city": "Межводное", "standart": 2490 },
    { "city": "Мисхор", "standart": 2390 },
    { "city": "Мрия Resort", "standart": 2290 },
    { "city": "Мысовое (Щелкино)", "standart": 3590 },
    { "city": "Николаевка", "standart": 1490 },
    { "city": "Новофедоровка", "standart": 1490 },
    { "city": "Новый Свет", "standart": 2490 },
    { "city": "Окуневка", "standart": 2590 },
    { "city": "Оленевка", "standart": 2690 },
    { "city": "Оползневое", "standart": 2290 },
    { "city": "Орджоникидзе", "standart": 2290 },
    { "city": "Орлиное", "standart": 2190 },
    { "city": "Орловка", "standart": 1790 },
    { "city": "Парковое", "standart": 2290 },
    { "city": "Партенит", "standart": 1790 },
    { "city": "Прибрежное (Саки)", "standart": 1590 },
    { "city": "Песчаное", "standart": 1690 },
    { "city": "Понизовка", "standart": 2290 },
    { "city": "Поповка", "standart": 2090 },
    { "city": "Рыбачье", "standart": 2290 },
    { "city": "Саки", "standart": 1590 },
    { "city": "Санаторное", "standart": 2290 },
    { "city": "Севастополь", "standart": 1990 },
    { "city": "Симеиз", "standart": 2290 },
    { "city": "Солнечная долина", "standart": 2490 },
    { "city": "Солнечногорское", "standart": 1900 },
    { "city": "Старый Крым", "standart": 1890 },
    { "city": "Судак", "standart": 2090 },
    { "city": "Утес", "standart": 2190 },
    { "city": "Учкуевка", "standart": 1790 },
    { "city": "Феодосия", "standart": 2290 },
    { "city": "Форос", "standart": 2190 },
    { "city": "Черноморское", "standart": 2990 },
    { "city": "Штормовое", "standart": 1990 },
    { "city": "Щелкино", "standart": 3590 },
    { "city": "Ялта", "standart": 1990 },
];
const adlerAero = [
    { "city": 'Алупка', "distance": 799 },
    { "city": 'Алушта', "distance": 710 },
    { "city": 'Гаспра', "distance": 759 },
    { "city": 'Гурзуф', "distance": 730 },
    { "city": 'Евпатория', "distance": 738 },
    { "city": 'Керчь', "distance": 477 },
    { "city": 'Оленевка', "distance": 831 },
    { "city": 'Оползневое', "distance": 789 },
    { "city": 'Саки', "distance": 714 },
    { "city": 'Севастополь', "distance": 756 },
    { "city": 'Симеиз', "distance": 796 },
    { "city": 'Симферополь', "distance": 665 },
    { "city": 'Судак', "distance": 618 },
    { "city": 'Феодосия', "distance": 662 },
    { "city": 'Ялта', "distance": 751 },
];
const anapaRailway = [
    { "city": 'Алупка', "distance": 408 },
    { "city": 'Алушта', "distance": 368 },
    { "city": 'Гаспра', "distance": 405 },
    { "city": 'Гурзуф', "distance": 378 },
    { "city": 'Евпатория', "distance": 385 },
    { "city": 'Керчь', "distance": 124 },
    { "city": 'Оленевка', "distance": 476 },
    { "city": 'Оползневое', "distance": 437 },
    { "city": 'Саки', "distance": 359 },
    { "city": 'Севастополь', "distance": 404 },
    { "city": 'Симеиз', "distance": 434 },
    { "city": 'Симферополь', "distance": 313 },
    { "city": 'Судак', "distance": 265 },
    { "city": 'Феодосия', "distance": 211 },
    { "city": 'Ялта', "distance": 376 },
];
const gelendgykBus = [
    { "city": 'Алупка', "distance": 488 },
    { "city": 'Алушта', "distance": 437 },
    { "city": 'Гаспра', "distance": 485 },
    { "city": 'Гурзуф', "distance": 457 },
    { "city": 'Евпатория', "distance": 462 },
    { "city": 'Керчь', "distance": 213 },
    { "city": 'Оленевка', "distance": 556 },
    { "city": 'Оползневое', "distance": 497 },
    { "city": 'Саки', "distance": 439 },
    { "city": 'Севастополь', "distance": 484 },
    { "city": 'Симеиз', "distance": 491 },
    { "city": 'Симферополь', "distance": 392 },
    { "city": 'Судак', "distance": 349 },
    { "city": 'Феодосия', "distance": 300 },
    { "city": 'Ялта', "distance": 485 },
];
const krasnodarRailway = [
    { "city": 'Алупка', "distance": 550 },
    { "city": 'Алушта', "distance": 499 },
    { "city": 'Гаспра', "distance": 547 },
    { "city": 'Гурзуф', "distance": 520 },
    { "city": 'Евпатория', "distance": 518 },
    { "city": 'Керчь', "distance": 258 },
    { "city": 'Оленевка', "distance": 613 },
    { "city": 'Оползневое', "distance": 561 },
    { "city": 'Саки', "distance": 496 },
    { "city": 'Севастополь', "distance": 538 },
    { "city": 'Симеиз', "distance": 555 },
    { "city": 'Симферополь', "distance": 454 },
    { "city": 'Судак', "distance": 400 },
    { "city": 'Феодосия', "distance": 350 },
    { "city": 'Ялта', "distance": 530 },
];

export { simferopol, adlerAero, anapaRailway, gelendgykBus,krasnodarRailway }
