import './App.css';
import Header from './components/Header/Header';
import Content from './components/Content/Content'
import MainForma from './components/MainForma/MainForma';
import Footer from './components/Footer/Footer';

function App(props) {

  return (
    <div className='app'>
      <div className='wrapper'>
        <Header />
        <MainForma />
        <Content />
        <Footer />
      </div>

    </div>
  );
}

export default App;
