import React from "react";
import s from './Header.module.css';
import viber from '../../img/viber.svg';
import telegram from '../../img/telegram.svg';
import whatsapp from '../../img/whatsapp.svg'

function Header() {
    return (<>
        <div className={s.header}>
            <div className={s.logoWrapper}>
                <div className={s.headerItem}>
                    <div className={s.logoItem}>
                        <div className={s.LogoIcon}></div>
                        <div className={s.logo}>CRIMEA.TAXI</div>
                        <div className={s.logoText}>Доставляем по назначению</div>
                    </div>
                </div>
            </div>
            {/* <div className={s.headerItem}>
                <div className={s.phoneWrapper}>
                    <div className={s.telicon}><img src={whatsapp} alt="" /></div>
                    <div className={s.tel}><a href='tel:+7 (985) 948-12-58'>+7 (985) 948-12-58</a></div>
                </div>
            </div> */}
            {/* <div className={s.headerItem}>
                <div className={s.phoneWrapper}>
                    <div className={s.telicon}>
                        <img src={whatsapp} alt="" />
                        <img src={viber} alt="" />
                        <img src={telegram} alt="" />
                    </div>
                    <div className={s.tel}><a href='tel:+7 (978) 768-86-01'>+7 (978) 768-86-01</a></div>

                </div>
            </div> */}
        </div>
    </>
    );
}


export default Header;