
import s from './Footer.module.css'

function Footer() {

    return (
        <div className={s.footerWrapper}>
            CRIMEA.TAXI предлагает качественный подбор транспортных услуг организациям и частным лицам в Крыму и за его пределами по разумным ценам. Мы хорошо знаем, что в современном мире время — деньги. Поэтому мы всегда пунктуальны. С 2015 года CRIMEA.TAXI оказывает лучший сервис в регионе.
            CRIMEA.TAXI - информационный сервис, не является перевозчиком. Транспортные и иные услуги оказываются партнёрами сервиса.
        </div>
    )
}

export default Footer;