import React from "react";
import s from './Content.module.css';
import {
  simferopol,
  adlerAero,
  anapaRailway,
  gelendgykBus,
  krasnodarRailway
} from "./price";
import 'react-accessible-accordion/dist/fancy-example.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const base = 25;
const komfort = 30;
const van = 35;
// const business = 36;
const anapa = getPrice(anapaRailway);
const adler = getPrice(adlerAero);
const gelengyk = getPrice(gelendgykBus);
const krasnodarGD = getPrice(krasnodarRailway);

function getPrice(arr) {
  return (
    arr.map((item) =>

      <div key={item + Math.random()} className={s.tbody}>
        <div className={s.city}>{item.city}</div>
        <div>{(item.distance * base).toString().slice(0, -2) + '00'} ₽</div>
        <div>{(item.distance * komfort).toString().slice(0, -2) + '00'} ₽</div>
        <div>{(item.distance * van).toString().slice(0, -2) + '00'} ₽</div>
      </div>
    )
  )
};

const simf = simferopol.map(price =>
  <div key={price.city} className={s.tbody}>
    <div className={s.city}>{price.city}</div>
    <div>{price.standart} ₽</div>
    <div>{(price.standart * 1.25).toString().slice(0, -4) + '00'} ₽</div>
    <div>{(price.standart * 1.75).toString().slice(0, -4) + '00'} ₽</div>
  </div>
);

function Content() {
  return (
    <div>
      <p className={s.text}>Расценки на основные направления:</p>
      <Accordion allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Адлер аэропорт ⇆ Крым
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className={s.grid}>
              <div className={s.thead}>
                <div>Направление</div>
                <div>Стандарт</div>
                <div>Комфорт/универсал</div>
                <div>Минивэн</div>
              </div>
              {adler}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Анапа ЖД вокзал ⇆ Крым
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className={s.grid}>
              <div className={s.thead}>
                <div>Направление</div>
                <div>Стандарт</div>
                <div>Комфорт/универсал</div>
                <div>Минивэн</div>
              </div>
              {anapa}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Геленджик автовокзал ⇆ Крым
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className={s.grid}>
              <div className={s.thead}>
                <div>Направление</div>
                <div>Стандарт</div>
                <div>Комфорт/универсал</div>
                <div>Минивэн</div>
              </div>
              {gelengyk}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Краснодар ЖД вокзал ⇆ Крым
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className={s.grid}>
              <div className={s.thead}>
                <div>Направление</div>
                <div>Стандарт</div>
                <div>Комфорт/универсал</div>
                <div>Минивэн</div>
              </div>
              {krasnodarGD}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton >
              Симферополь аэропорт или ЖД вокзал ⇆ Крым
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className={s.grid}>
              <div className={s.thead}>
                <div>Направление</div>
                <div>Стандарт</div>
                <div>Комфорт/универсал</div>
                <div>Минивэн</div>
              </div>
              {simf}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

export default Content;